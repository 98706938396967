import styles from './FooterDisclaimer.module.css'
import HTMLRender from '@components/core/HTMLRender';
import get from 'lodash/get';
import { useStore } from '@lib/store';
import { shallow } from 'zustand/shallow';
import Link from '@src/components/elements/Link';

const FooterDisclaimer = (props) => {
  const currentProductType = useStore((state) => state.currentProductType);
  const data = get(props, 'data.0', props)
  if (!props.data.length > 0 || data === null || data === undefined) return null;

  const store = useStore(
    (store) => ({
      locale: store.locale,
    }),
    shallow
  );

  return (
    <>
      <div id="footer_disclaimer" className={`${styles.disclaimer}`}>
        <HTMLRender data={currentProductType?.attributes?.footer_disclaimer} />
      </div>
      <div className='mt-lg flex'>
        {store && store?.locale === "en" || store?.locale === "lat" || store?.locale === "de" ?
          <div className='text-center lg:ltr:text-left lg:rtl:text-right ltr:pr-sm rtl:pl-sm'>
            <a
              target="_blank noreferrer"
              href={`https://capex.com/${store?.locale}/online-trading/sitemap`}
              className='text-[#999999] hover:text-secondaryHover text-sm lg:text-base'>
              Online Trading Sitemap
            </a>
          </div> : null}
        <div className='text-center lg:ltr:text-left lg:rtl:text-right text-[#999999] hover:text-secondaryHover text-sm lg:text-base'>
          <Link text="Sitemap" href="/sitemap" />
        </div>
      </div>
    </>
  );
};

export default FooterDisclaimer;



import NextImage from "@components/elements/Image";
import styles from "./FooterPayments.module.css";
import get from "lodash/get";
import SectionTitle from "@components/elements/SectionTitle";
import { useStore } from "@lib/store";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { shallow } from 'zustand/shallow'

const FooterPayments = (props) => {
  const data = get(props, "data.0", props);
  const locale = get(props, "locale");

  const [hidePaymentMethods, setHidePaymentMethods] = useState(false);
  const hidePaymentMethodsPage = useStore(store => store.hide_payment_methods, shallow);
  const router = useRouter();


  useEffect(() => {
    setHidePaymentMethods(hidePaymentMethodsPage);
  }, [router?.asPath, hidePaymentMethodsPage]);




  if (!data || hidePaymentMethods === true || locale === 'es') return null;

  return (
    <>
      <div className="border-b-[#7c7c7c] border-opacity-50 pb-lg lg:border-b lg:pb-xl">
        <div className={styles.title}>
          <SectionTitle data={data?.section_title} />
        </div>
        <ul className="flex items-center justify-between pt-lg lg:py-[2.222rem] lg:pt-[3.889rem]">
          <div className="flex w-full flex-wrap items-center justify-between">
            {data.images?.data?.map((payment, index) => (
              <li
                key={index}
                className="relative my-sm flex w-full max-w-[calc(100%/3-0.5rem)] justify-center lg:my-0 lg:w-auto lg:max-w-[10.625rem]"
              >
                <NextImage
                  alt={payment?.attributes?.alternativeText || ""}
                  src={payment?.attributes?.url}
                  width={payment?.attributes?.width}
                  height={payment?.attributes?.height}
                />
              </li>
            ))}
          </div>
        </ul>
      </div>
      <div className="mx-auto h-[1px] w-full max-w-[40%] bg-[#7c7c7c] lg:hidden" />
    </>
  );
};

export default FooterPayments;

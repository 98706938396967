import useGlobalVariable from "@lib/store/hooks/useGlobalVariables";
import { Divider, Grid, Select, Text, Title } from "@mantine/core";
import Arrow from "@src/components/elements/Arrow";
import SectionTitle from "@src/components/elements/SectionTitle";
import { useEffect, useState } from "react";
import { LegalDocumentFile, LegalDocumentItem } from "../legal-documents-view";
import styles from "./LegalDocumentsQualityOfExecution.module.css";

const LegalDocumentsQualityOfExecution = (props) => {
  const data = props.data || props;
  const { gv, isValidating } = useGlobalVariable();
  const [_file, _setFile] = useState(null);
  const [_name, _setName] = useState(null);
  const [dropdownOneOpened, setDropdownOneOpened] = useState(false);
  const [dropdownTwoOpened, setDropdownTwoOpened] = useState(false);

  let options = {
    year: [
      `2020`,
      `2021`,
      `2022`,
      `2023`,
      `2024`,
    ],
    quarter: [
      `1`,
      `2`,
      `3`,
      `4`
    ],
  };

  const [state, setState] = useState({
    year: options?.year[options?.year?.length - 1],
    quarter: options?.quarter[options?.quarter?.length - 1]
  });

  if (state?.year === options?.year[2]) {
    options.quarter = [`4`];
  }
  if (state?.year === options?.year[3]) {
    options.quarter = ['1', '2', '3', '4'];
  }
  if (state?.year === options?.year[4]) {
    options.quarter = ['1'];
  }

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      quarter: options?.quarter[options?.quarter?.length - 1] || 4,
    }));
  }, [state.year]);

  useEffect(() => {
    if (!isValidating && props?.files?.length > 0) {
      const findFile = props?.files?.find(el => state?.quarter === el?.quarter?.replace('_', '') && state?.year === el?.Year?.replace('_', ''));
      _setFile(findFile || null);
      _setName(findFile ? findFile?.name : null);
    }
  }, [options, state]);

  if (!data) return null;

  return (
    <div className="container">
      {data?.section_title && <SectionTitle data={data?.section_title} />}
      <div className="mt-lg py-xl border-t border-b border-[#cccc]">
        <div className="flex flex-col lg:flex-row w-full justify-between items-center">
          <div className="w-full lg:max-w-[calc(100%/3-0.5rem)] mb-md lg:mb-0">
            <Select
              label={`${gv('year')}:`}
              data={options?.year}
              value={state.year}
              size="md"
              radius="sm"
              onChange={(value) => {
                setState({
                  ...state,
                  year: value
                });
              }}
              onDropdownOpen={() => setDropdownOneOpened(true)}
              onDropdownClose={() => setDropdownOneOpened(false)}
              rightSection={
                <div className={`${dropdownOneOpened ? 'text-white' : 'text-secondary'}`}>
                  <Arrow direction="down" />
                </div>
              }
              className={styles["mantine-NativeSelect-wrapper"]}
              classNames={{
                wrapper: styles.wrapper,
                input: dropdownOneOpened ? styles.selectInputOpened : styles.selectInputClosed,
                root: styles.selectRoot,
                rightSection: styles.rightSection,
                item: styles.itemSelect,
                dropdown: styles.dropdown
              }}
            />
          </div>
          <div className="w-full lg:max-w-[calc(100%/3-0.5rem)] mb-md lg:mb-0">
            <Select
              label={`${gv('quarter')}:`}
              data={options?.quarter}
              value={state.year === options?.year[2] ? options?.quarter[0] : state.quarter}
              size="md"
              radius="sm"
              onChange={(value) => {
                setState({
                  ...state,
                  quarter: value
                });
              }}
              onDropdownOpen={() => setDropdownTwoOpened(true)}
              onDropdownClose={() => setDropdownTwoOpened(false)}
              rightSection={
                <div className={`${dropdownTwoOpened ? 'text-white' : 'text-secondary'}`}>
                  <Arrow direction="down" />
                </div>
              }
              className={styles["mantine-NativeSelect-wrapper"]}
              classNames={{
                wrapper: styles.wrapper,
                input: dropdownTwoOpened ? styles.selectInputOpened : styles.selectInputClosed,
                root: styles.selectRoot,
                rightSection: styles.rightSection,
                item: styles.itemSelect,
                dropdown: styles.dropdown
              }}
            />
          </div>
          <div className="w-full lg:max-w-[calc(100%/3-0.5rem)] mt-lg lg:mt-0 lg:pt-[24px]">
            {_file && <LegalDocumentFile file={_file} name={_name} />}
            {!_file && (
              <p>
                {gv('quarters_no_file', {
                  type: 'html',
                  template: {
                    quarter: gv('quarter') + ' ' + state?.quarter,
                    year: gv('year') + ' ' + state?.year,
                  }
                })}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalDocumentsQualityOfExecution;
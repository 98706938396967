/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
import Footer from "@components/sections/Footer";
import Header from "@components/sections/Header";
import { QuickEditPage } from "@components/custom/QuickEdit";
import ZendeskChat from "@components/custom/ZendeskChat";
import layoutDictionary from "./layout_component";
import StickyRiskWarning from "@src/components/custom/StickyRiskWarning";
import WrongLicenceBar from "@src/components/custom/WrongLicenceBar";
import useSessionStorage from "@lib/store/hooks/useSessionStorage";
import { createContext, useEffect, useState } from "react";
import Head from "next/head";
import Script from "next/script";
import AlternatesSection, { getFullUrl } from "@src/components/custom/Seo/Alternates";
import get from "lodash/get";
import replace from "lodash/replace";
import { useRouter } from 'next/router';
import { useStore } from "@lib/store";
export default function Layout({ pageData, pageType }) {
  const UseComponent = layoutDictionary[pageType];
  const router = useRouter();
  const locale = useStore((state) => state.locale);
  const [stateEmbedded, setStateEmbedded] = useState(false);
  // const { embedded } = router.query;
  useEffect(() => {
    const queryKey = 'embedded';
    let embedded = false;
    try {
      embedded = router.query[queryKey] || router.asPath.match(new RegExp(`[&?]${queryKey}=(.*?)(&|$)`))?.[1];
      if (embedded === 'true') {
        embedded = true;
      } else {
        embedded = false;
      }
    } catch (error) {
      embedded = false;
    }
    if (embedded !== stateEmbedded) {
      setStateEmbedded(embedded)
    }

  }, [router.query, router.asPath]);




  const isIframe = get(pageData, "page_key", "").includes("iframe");
  const currentProductType = useStore((state) => state.currentProductType);

  if (!UseComponent) return null;
  const localizationVersions = {};
  [
    { attributes: { locale: pageData?.locale, slug: pageData?.slug, publishedAt: pageData?.publishedAt } },
    ...(pageData?.localizations?.data || []),
    ...(pageData?.alternates?.english || []),
    ...(pageData?.alternates?.spanish || []),
    ...(pageData?.alternates?.arabic || []),
  ].map((item, index) => {
    if (item?.attributes?.publishedAt) {
      localizationVersions[item.attributes.locale] = item.attributes.slug;
    }
    if (item?.type === "alternate") {
      localizationVersions[item.locale] = item.slug;
    }
    return item?.attributes || item;
  });
  const fullSlugClear = replace(pageData?.fullSlug, /\/pagination\/1/gi, "") || '';
  return (
    <>
      <Head>
        <link rel="canonical" href={fullSlugClear} />
      </Head>
      <AlternatesSection
        pageType={pageType}
        localizationVersions={localizationVersions}
        alternates={pageData?.alternates}
        locale={pageData?.locale}
      />

      <Head>
        {/* self refering canonical */}

        {/* alternate for articles/academy lessons */}

        <link rel="icon" type="image/x-icon" href="/assets/logo/favicon.ico" />
        <meta name="theme-color" content="#111114" />
      </Head>
      <QuickEditPage pageData={pageData} />

        {!isIframe && stateEmbedded === false && (
          <div>
            <WrongLicenceBar />
            {currentProductType?.attributes?.risk_warning_type === "adgm" && <StickyRiskWarning currentProductType={currentProductType} id="adgm_desktop_risk_warning" key="adgm_desktop_risk_warning"/> }
            <Header />
          </div>
        )}

        <main dir={pageData?.direction}>
          <UseComponent pageType={pageType} pageData={pageData} />
        </main>
        {!isIframe && stateEmbedded === false && <Footer />}
        {!isIframe && stateEmbedded === false && currentProductType?.attributes?.risk_warning_type === "cysec" && (
           <StickyRiskWarning id="cysec_sticky_disclaimer" currentProductType={currentProductType} key="cysec_sticky_disclaimer"/>
        )}
      
      {!isIframe && stateEmbedded === false && <ZendeskChat locale={locale || false} />}
      {!isIframe && stateEmbedded === false && (
        <Script strategy="lazyOnload" id="gtag-manager">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PHJRCT4');`}
        </Script>
      )}
      {!isIframe && stateEmbedded === false && (
        <Script
          strategy="lazyOnload"
          id="ck-disclaimer"
          data-cbid="0e783eed-30a0-4a3c-b331-83d8a0c9ac3c"
          src="https://consent.cookiebot.com/uc.js"
        />
      )}
    </>
  );
}
